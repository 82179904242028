import React, { useState, useContext, useEffect } from 'react';

import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BgImage from './durin_gate_dark.jpg';
import { ThemeContext, AuthContext, UserLinkContext, FormContext } from './Contexts.js';
import { getMetadata } from "./services/ApiService";
import { getStateEncrypted, saveStateEncrypted } from "./services/StateService";

import { ForbiddenPage } from "./ForbiddenPage.js"
import { ErrorPage } from "./ErrorPage.js"
import { NoPage } from "./NoPage.js"
import { SuccessPage } from "./SuccessPage.js"
import { GroupStatsForm } from "./GroupStatsForm.js"

const PrivateRoute = ({ children }) => {
    const isAuthenticated = useContext(AuthContext);
    return isAuthenticated
        ? children
        : <Navigate to="/forbidden" />;
}

const App = () => {
    const [theme,
        setTheme] = useState({ backgroundImage: `url(${BgImage})` });
    const [isAuthenticated,
        setIsAuthenticated] = useState(null);
    const [isLoading,
        setIsLoading] = useState(true);
    const [formMetadata,
        setFormMetadata] = useState(null);
    const [formData,
        setFormData] = useState({});
    const [userLink,
        setUserLink] = useState(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));

    const [browserKey,
        setBrowserKey] = useState(null);
    const [storageId,
        setStorageId] = useState(null);

    const formContext = { "formData": formData, "updateFormData": setFormData, "formMetadata": formMetadata }

    useEffect(() => {
        saveStateEncrypted(storageId, browserKey, formData);
    }, [storageId, browserKey, formData]);

    if (isAuthenticated == null) {
        getMetadata(userLink).then(res => {
            setFormMetadata(res?.content);
            setFormData(getStateEncrypted(res?.content?.storageId, res?.content?.browserKey));
            setBrowserKey(res?.content?.browserKey)
            setStorageId(res?.content?.storageId)
            setIsAuthenticated(true);
            setIsLoading(false)
        }).catch(e => {
            setIsAuthenticated(false);
            setIsLoading(false)
        });
    }

    return (<> {
        isLoading
            ? (
                <div className="text-center">
                    <div className="spinner-border" role="status" />
                    <div className="spinner-grow" role="status" />
                    <span className="sr-only centered-spinner-txt">Завантаження...</span>
                </div>
            )
            : (
                <UserLinkContext.Provider value={userLink}>
                    <AuthContext.Provider value={isAuthenticated}>
                        <FormContext.Provider value={formContext}>
                            <ThemeContext.Provider value={theme}>
                                <BrowserRouter>
                                    <Routes path="/">
                                        <Route index element={<PrivateRoute children={<NoPage />} />} />
                                        <Route path="/forms/*" element={<PrivateRoute children={<GroupStatsForm />} />} />
                                        <Route path="/forms/success/*" element={<PrivateRoute children={<SuccessPage />} />} />
                                        <Route path="/forms/error/*" element={<PrivateRoute children={<ErrorPage />} />} />
                                        <Route path="/forms/forbidden/*" element={<PrivateRoute children={<ForbiddenPage />} />} />
                                        <Route path="/nopage" element={<PrivateRoute children={<NoPage />} />} />
                                        <Route path="/forbidden" element={< ForbiddenPage />} />
                                    </Routes>
                                </BrowserRouter>
                            </ThemeContext.Provider>
                        </FormContext.Provider>
                    </AuthContext.Provider>
                </UserLinkContext.Provider>

            )
    } </>
    )
};

export default App;