import CryptoJS from "crypto-js";

const clearState = async (storageId) => {
    try {
        const stateName = storageId ? "crypto.state." + storageId.toString(): "crypto.state"
        localStorage.clear(stateName);
    } catch (error) {
    }
}

export const saveStateEncrypted = async (storageId, key, data) => {
    try {
        if (!key) {
            return;
        }
        const plaintextJSON = JSON.stringify(data);
        var ciphertext = CryptoJS.AES.encrypt(plaintextJSON, key).toString();
        localStorage.setItem("crypto.state." + storageId.toString(), ciphertext)
    } catch (error) {
        clearState(storageId)
    }
};

export const getStateEncrypted = (storageId, key) => {

    try {
        const legacyEncryptedText = localStorage.getItem("crypto.state");
        
        const encryptedText = localStorage.getItem("crypto.state." + storageId.toString());
        if (!encryptedText && !legacyEncryptedText) {
            return {};
        }
        if (!encryptedText && legacyEncryptedText) {
            encryptedText = legacyEncryptedText;
        }

        var bytes = CryptoJS.AES.decrypt(encryptedText, key);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(originalText)
    } catch (error) {
        clearState(storageId)
        return {};
    }

};